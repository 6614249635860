.host {
  --0: 0;
  --1: 1px;
  --2: 2px;
  --elevation-level-1: 0 2px 3px 0 #999;
  --elevation-level-2: 0 2px 6px 0 #999;
  --elevation-level-3: 0 2px 9px 0 #999;
  --elevation-level-4: 0 2px 12px 0 #999;
  --padding-xs: 4px;
  --padding-s: 8px;
  --padding-m: 16px;
  --padding-l: 24px;
  --padding-xl: 32px;
  --padding-2xl: 64px;
  --space-between-xs: 4px;
  --space-between-s: 8px;
  --space-between-m: 16px;
  --space-between-l: 24px;
  --space-between-xl: 32px;
  --space-between-2xl: 64px;
  --border-radius-xs: 2px;
  --border-radius-s: 4px;
  --border-radius-m: 8px;
  --border-radius-l: 16px;
  --border-radius-xl: 24px;
  --cta-radius: 8px;
  --cta-focus: 0 0 0 2px #fff, 0 0 0 4px #0073a8;
  --font-family-light: on air;
  --font-family-regular: on air;
  --font-family-medium: on air;
  --font-family-bold: on air;
  --font-family-black: on air;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-weight-black: 900;
  --line-height-100: 0.75rem;
  --line-height-100-med: 0.9375rem;
  --line-height-200: 0.875rem;
  --line-height-200-med: 1.0938rem;
  --line-height-300: 1.5rem;
  --line-height-400: 1.875rem;
  --line-height-500: 1.5rem;
  --line-height-600: 2.1875rem;
  --line-height-700: 2.25rem;
  --line-height-800: 2.75rem;
  --line-height-900: 3.75rem;
  --font-size-100: 0.75rem;
  --font-size-200: 0.875rem;
  --font-size-300: 1rem;
  --font-size-400: 1.25rem;
  --font-size-500: 1.5rem;
  --font-size-600: 1.75rem;
  --font-size-700: 2.25rem;
  --font-size-800: 2.75rem;
  --font-size-900: 3.75rem;
  --paragraph-spacing-1: 1rem;
  --text-decoration-none: none;
  --text-decoration-line-through: line-through;
  --text-decoration-underline: underline;
  --input-radius: 8px;
  --input-border-width: 2px;
  --input-space-between: 4px;
  --input-accordion-radius: 8px;
  --segmented-control-radius-outer: 8px;
  --segmented-control-radius-inner: 6px;
  --border-width-1: 1px;
  --border-width-2: 2px;
  --border-width-3: 3px;
  --color-surface-bg: #fff;
  --color-surface-bg-dim: #f2f2f2;
  --color-surface-bg-bright: #fff;
  --color-surface-fg: #006;
  --color-surface-fg-variant: #4d4d4d;
  --color-surface-outline: #ccc;
  --color-surface-outline-variant: #333;
  --color-primary-bg: #0050ff;
  --color-primary-bg-hover: #006;
  --color-primary-bg-hover-tint: #d4e2ff;
  --color-primary-bg-focus: #006;
  --color-primary-bg-disabled: #b3b3b3;
  --color-primary-fg: #fff;
  --color-primary-fg-tint: #d4e2ff;
  --color-primary-fg-disabled: #333;
  --color-secondary-bg: #fff;
  --color-secondary-bg-hover: #fff;
  --color-secondary-bg-hover-tint: #d4e2ff;
  --color-secondary-bg-focus: #006;
  --color-secondary-bg-disabled: #b3b3b3;
  --color-secondary-fg: #0050ff;
  --color-secondary-fg-tint: #d4e2ff;
  --color-secondary-fg-disabled: #333;
  --color-tertiary-bg: #0050ff;
  --color-tertiary-bg-hover: #e5f1f7;
  --color-tertiary-bg-hover-tint: #e5f1f7;
  --color-tertiary-bg-focus: #fff;
  --color-tertiary-bg-disabled: #b3b3b3;
  --color-tertiary-fg: #fff;
  --color-tertiary-fg-tint: #d4e2ff;
  --color-tertiary-fg-disabled: #333;
  --color-destructive-bg: #e21c33;
  --color-destructive-bg-hover: #e21c33;
  --color-destructive-bg-hover-tint: #f9e7e6;
  --color-destructive-bg-focus: #e21c33;
  --color-destructive-bg-disabled: #b3b3b3;
  --color-destructive-fg: #fff;
  --color-destructive-fg-tint: #f9e7e6;
  --color-destructive-fg-disabled: #333;
  --color-error-bg: #e21c33;
  --color-error-bg-dim: #e21c33;
  --color-error-bg-bright: #f9e7e6;
  --color-error-fg: #fff;
  --color-warning-bg: #e8733b;
  --color-warning-bg-dim: #e8733b;
  --color-warning-bg-bright: #fff;
  --color-warning-fg: #fff;
  --color-success-bg: #0daa04;
  --color-success-bg-dim: #0daa04;
  --color-success-bg-bright: #fff;
  --color-success-fg: #fff;
  --color-info-bg: #0019a5;
  --color-info-bg-dim: #41b6e6;
  --color-info-bg-bright: #e5f1f7;
  --color-info-fg: #fff;
  --color-gradient-gradient-alpha: linear-gradient(
    180deg,
    #23236a 0%,
    rgb(16 122 192 / 67.1%) 100%
  );
  --color-gradient-gradient-bravo: linear-gradient(
    180deg,
    #1f2668 0%,
    #1a337a 10.35%,
    #1f4991 20.81%,
    #1b65a8 33.77%,
    #168ac8 48.06%,
    #46aadb 62.33%,
    #82c8e5 78.67%,
    #a2d5e8 90.69%,
    #c5e3ed 100%
  );
  --color-gradient-gradient-charlie: linear-gradient(
    199deg,
    #bb0a00 0%,
    #ed0000 100%
  );
  --color-gradient-gradient-volt: linear-gradient(
    90deg,
    #ed0000 0%,
    #081eab 100%
  );
  --color-custom-graph-bg: #5f2878;
  --color-custom-link-fg: #0050ff;
  --color-custom-graph-fg: #d9d9d9;
  --color-custom-badge-highlight: #953698;
  --color-custom-badge-feature: #01b7b4;
  --color-custom-badge-info: #fff;
  --color-custom-badge-permission: #006;
  --color-custom-badge-offer: #cb31a0;
  --color-custom-badge-offer-bright: #ffe6f4;
  --color-custom-badge-sale: #e21c33;
  --color-custom-badge-fg: #fff;
  --color-custom-badge-fg-variant: #000;
  --color-custom-footer-bg: #0019a5;
  --color-custom-label-fg: #4c4c94;
  --color-custom-text-fg: #333;
  --color-custom-icon-fg: #ff0190;
  --mo-animation-duration-super-fast: 50ms;
  --mo-animation-duration-extra-fast: 100ms;
  --mo-animation-duration-fast: 150ms;
  --mo-animation-duration-normal: 300ms;
  --mo-animation-duration-slow: 500ms;
  --mo-animation-duration-super-slow: 2100ms;
  --mo-animation-timing: linear;
  --b: #0019a5;
  --mo-brand-primary: #0073a8;
  --mo-brand-primary-contrast: #fff;
  --mo-brand-primary-active: #006;
  --mo-brand-secondary-interactive: #e5f1f7;
  --mo-brand-tertiary: #5f2878;
  --mo-brand-disabled: #9a9a9a;
  --mo-brand-disabled-contrast: #fff;
  --mo-color-focus: #006;
  --mo-backdrop: #000;
  --mo-light-backdrop: #fff;
  --mo-color-100: #000;
  --mo-color-80: #333;
  --mo-color-50: #979797;
  --mo-color-40: #9a9a9a;
  --mo-color-20: #bebebe;
  --mo-color-10: #d8d8d8;
  --mo-color-05: #efefef;
  --mo-color-0: #fff;
  --mo-color-error: #ed0000;
  --mo-color-warning: #e8733b;
  --mo-color-success: #348432;
  --mo-color-info: #007bb2;
  --mo-font-family: 'on-air-momentum', arial, helvetica, sans-serif;
  --mo-container-max-width: 1200px;
  --mo-container-gutter: 16px;
  --mo-container-gap: 16px;
  --mo-border-radius-lg: 6px;
  --mo-border-radius-md: 4px;
  --mo-sizing-3xl: 3.75rem;
  --mo-sizing-3xl-px: 60px;
  --mo-sizing-2xl: 2rem;
  --mo-sizing-2xl-px: 32px;
  --mo-sizing-xl: 1.5rem;
  --mo-sizing-xl-px: 24px;
  --mo-sizing-lg: 1.25rem;
  --mo-sizing-lg-px: 20px;
  --mo-sizing-md: 1rem;
  --mo-sizing-md-px: 16px;
  --mo-sizing-sm: 0.75rem;
  --mo-sizing-sm-px: 12px;
  --mo-sizing-xs: 0.5rem;
  --mo-sizing-xs-px: 8px;
  --mo-sizing-2xs: 0.25rem;
  --mo-sizing-2xs-px: 4px;
  --mo-sizing-3xs: 0.125rem;
  --mo-sizing-3xs-px: 2px;
  --mo-calendar-controls-margin-bottom: 8px;
  --mo-calendar-cell-padding: 4px;
  --mo-calendar-header-padding: 0 0 8px;
  --mo-calendar-control-color: var(--color-primary-bg);
  --mo-calendar-control-disabled-color: var(--mo-color-50);
  --mo-calendar-date-color: var(--color-surface-fg);
  --mo-calendar-date-font-size: var(--font-size-300);
  --mo-calendar-date-font-weight: var(--font-weight-bold);
  --mo-calendar-date-line-height: var(--line-height-300);
  --mo-calendar-day-color: var(--color-primary-bg);
  --mo-calendar-day-border: none;
  --mo-calendar-day-background: transparent;
  --mo-calendar-day-available-color: var(--color-primary-bg);
  --mo-calendar-day-available-border: 1px solid var(--color-primary-bg);
  --mo-calendar-day-available-background: transparent;
  --mo-calendar-day-unavailable-color: var(--mo-color-10);
  --mo-calendar-day-unavailable-border: none;
  --mo-calendar-day-unavailable-background: transparent;
  --mo-calendar-day-selected-color: var(--color-success-fg);
  --mo-calendar-day-selected-border: 1px solid var(--color-success-bg);
  --mo-calendar-day-selected-background: var(--color-success-bg);
  --mo-calendar-day-today-color: var(--color-primary-bg);
  --mo-calendar-day-today-border: none;
  --mo-calendar-day-today-background: var(--mo-color-10);
  --mo-calendar-day-outside-month-opacity: 0.4;
  --mo-calendar-day-outside-month-background: transparent;
  --mo-calendar-day-text-transform: translateY(2px);
  --form-list-background-color: var(--color-surface-bg);
  --form-list-border-color: var(--color-surface-outline);
  --form-list-error-border-color: var(--color-error-bg);
  --form-list-disabled-border-color: var(--color-primary-bg-disabled);
  --form-list-disabled-background-color: var(--color-primary-bg-disabled);
  --form-list-item-border-color: transparent;
  --form-list-item-background-color: transparent;
  --form-list-item-selected-border-color: var(--color-primary-bg);
  --form-list-item-selected-background-color: var(--color-surface-bg-dim);
  --form-list-item-hover-background-color: var(--color-surface-bg-dim);
  --mo-filter-chip-background-color: var(--color-surface-bg);
  --mo-filter-chip-border-color: var(--color-surface-outline);
  --mo-filter-chip-text-color: var(--color-surface-fg);
  --mo-filter-chip-padding: var(--mo-sizing-sm) var(--mo-sizing-xl)
    var(--mo-sizing-xs) var(--mo-sizing-xl);
  --mo-filter-chip-active-background-color: var(--color-surface-bg);
  --mo-filter-chip-active-border-color: var(--color-secondary-bg);
  --mo-filter-chip-active-text-color: var(--color-secondary-bg);
  --mo-filter-chip-active-indicator-color: var(--color-secondary-bg);
  --mo-filter-chip-active-padding: var(--mo-sizing-sm) var(--mo-sizing-md)
    var(--mo-sizing-xs) var(--mo-sizing-2xl);
  --mo-filter-chip-invalid-background-color: var(--color-surface-bg);
  --mo-filter-chip-invalid-border-color: var(--color-error-bg);
  --mo-filter-chip-invalid-text-color: var(--color-error-bg);
  --mo-filter-chip-invalid-selected-background-color: var(--color-error-bg);
  --mo-filter-chip-invalid-selected-border-color: var(--color-error-bg);
  --mo-filter-chip-invalid-selected-text-color: var(--color-error-fg);
  --mo-filter-chip-invalid-selected-indicator-color: var(--color-error-fg);
  --mo-filter-chip-disabled-background-color: var(--color-surface-bg);
  --mo-filter-chip-disabled-border-color: var(--color-primary-bg-disabled);
  --mo-filter-chip-disabled-text-color: var(--color-primary-bg-disabled);
  --mo-filter-chip-disabled-indicator-color: var(--color-primary-bg-disabled);
  --mo-filter-chip-indicator-size: 0.5rem;
  --mo-filter-chip-indicator-indent: var(--mo-sizing-sm);
  --mo-filter-chip-indicator-border-width: 2px;
  --mo-header-padding-x: var(--mo-container-gutter);
  --mo-header-padding-y: 8px;
  --mo-header-md-padding-y: 16px;
  --mo-header-lg-padding-y: 16px;
  --mo-header-height: 66px;
  --mo-header-md-height: 80px;
  --mo-header-lg-height: 100px;
  --mo-header-logo-width: 34px;
  --mo-header-logo-height: 35px;
  --mo-header-logo-md-width: 46px;
  --mo-header-logo-md-height: 48px;
  --mo-header-logo-lg-width: 58px;
  --mo-header-logo-lg-height: 60px;
  --mo-header-decoration-primary-color: #0019a5;
  --mo-header-decoration-primary-contrast-color: #0019a5;
  --mo-header-decoration-primary-text-color: var(--mo-color-0);
  --mo-header-decoration-secondary-color: #0019a5;
  --mo-header-decoration-secondary-contrast-color: #0019a5;
  --mo-header-decoration-secondary-text-color: var(--mo-color-0);
  --mo-header-decoration-primary-gradient-color: 255, 255, 255;
  --mo-header-decoration-secondary-gradient-color: 255, 255, 255;
  --mo-header-icon-color: var(--mo-color-0);
  --mo-media-border: 1px solid var(--mo-color-20);
  --mo-poptip-width: 275px;
  --mo-poptip-height: 175px;
  --mo-poptip-shadow: 0 0 5px 1px var(--mo-poptip-border-color);
  --mo-poptip-before-width: 30px;
  --mo-poptip-before-height: 15px;
  --mo-poptip-after-width: 15px;
  --mo-poptip-after-height: 15px;
  --mo-poptip-after-shadow: 1px 1px 5px var(--mo-poptip-border-color);
  --mo-poptip-content-height: 95px;
  --mo-poptip-border-radius: 6px;
  --mo-poptip-padding: 10px 15px;
  --mo-poptip-background-color: var(--mo-color-05);
  --mo-poptip-border-color: var(--mo-color-20);
  --mo-poptip-close-size: 2.75rem;
  --mo-promo-flag-rounded-text-transform: uppercase;
  --mo-promo-flag-padding: var(--mo-sizing-sm-px) var(--mo-sizing-xl-px)
    var(--mo-sizing-sm) var(--mo-sizing-md-px);
  --mo-promo-flag-rounded-padding: var(--mo-sizing-sm-px) var(--mo-sizing-xl-px)
    var(--mo-sizing-xs);
  --mo-promo-flag-clip-path: polygon(
    0 0,
    100% 0,
    calc(100% - 20px) 100%,
    0% 100%
  );
  --mo-promo-flag-flourish-clip-path: polygon(0 0, 100% 100%, 100% 0);
  --mo-promo-flag-flourish-width: var(--mo-sizing-sm);
  --mo-promo-flag-flourish-height: var(--mo-sizing-xs);
  --mo-promo-flag-primary-flourish-colour: var(--mo-color-80);
  --mo-promo-flag-secondary-flourish-colour: var(--mo-color-80);
  --mo-promo-flag-tertiary-flourish-colour: var(--mo-color-80);
  --mo-promo-flag-unchecked-flourish-colour: var(--mo-brand-tertiary-active);
  --mo-promo-flag-unchecked-background-color: var(--mo-color-50);
  --mo-promo-flag-unchecked-color: var(--mo-color-0);
  --mo-promo-flag-success-flourish-colour: var(--mo-color-80);
  --mo-promo-flag-info-flourish-colour: var(--mo-color-80);
  --mo-sidenav-width: 80vw;
  --mo-sidenav-max-width: 360px;
  --tab-color: var(--mo-color-80);
  --active-tab-color: var(--mo-brand-tertiary);
  --segmented-tab-list-gap: 0;
  --segmented-tab-list-border-width: 0;
  --segmented-tab-border-radius: 0;
  --segmented-tab-border-color: var(--color-surface-outline);
  --segmented-tab-color: var(--color-tertiary-bg);
  --segmented-tab-padding: var(--padding-m) var(--padding-l);
  --segmented-active-tab-background-color: var(--color-surface-bg);
  --segmented-tab-focus-box-shadow: var(--cta-focus-inset);
  --mo-tabs-font-weight: var(--font-weight-regular);
  --mo-tabs-font-size: var(--font-size-300);
  --mo-tabs-line-height: var(--line-height-300);
  --mo-tabs-tab-color: var(--mo-color-80);
  --mo-tabs-tab-background-color: var(--mo-color-0);
  --mo-tabs-tab-border-color: var(--mo-brand-secondary);
  --mo-tabs-tab-padding-block: var(--mo-sizing-sm-px);
  --mo-tabs-tab-padding-inline: var(--mo-sizing-md-px);
  --mo-tabs-tab-border-width: 2px;
  --mo-tabs-tab-active-background-color: var(--mo-color-0);
  --mo-tabs-tab-active-border-color: var(--mo-brand-secondary);
  --mo-tabs-tab-active-color: var(--mo-color-80);
  --mo-tabs-segmented-background-color: var(--mo-color-0);
  --mo-tabs-segmented-border-color: var(--mo-color-20);
  --mo-tabs-segmented-border-width: 0;
  --mo-tabs-segmented-border-radius: 40px;
  --mo-tabs-segmented-gap: 0;
  --mo-tabs-segmented-padding: 0;
  --mo-tabs-segmented-padding-md: 0;
  --mo-tabs-segmented-tab-padding-top: var(--padding-m);
  --mo-tabs-segmented-tab-padding-bottom: var(--padding-m);
  --mo-tabs-segmented-tab-padding-left: var(--padding-l);
  --mo-tabs-segmented-tab-padding-right: var(--padding-l);
  --mo-tabs-segmented-tab-focus-box-shadow: var(--cta-focus-inset);
  --mo-tabs-segmented-tab-background-color: var(--mo-color-0);
  --mo-tabs-segmented-tab-border-color: var(--color-surface-outline);
  --mo-tabs-segmented-tab-color: var(--color-tertiary-bg);
  --mo-tabs-segmented-tab-border-width: 2px;
  --mo-tabs-segmented-tab-border-radius: 0;
  --mo-tabs-segmented-tab-first-last-border-radius: var(--cta-radius);
  --mo-tabs-segmented-tab-active-background-color: var(--mo-brand-secondary);
  --mo-tabs-segmented-tab-active-border-color: var(--mo-brand-secondary);
  --mo-tabs-segmented-tab-active-color: var(--mo-brand-tertiary);
  --mo-tabs-tiled-padding: var(--mo-sizing-md-px) var(--mo-sizing-xl-px);
  --mo-tabs-tiled-tab-border-radius: var(--mo-border-radius-md);
  --mo-tabs-tiled-tab-border-color: var(--mo-color-40);
  --mo-tabs-tiled-tab-border-width: 2px;
  --mo-tabs-tiled-tab-gap: 6px;
  --mo-tabs-tiled-active-border-color: var(--mo-brand-secondary);
  --mo-tabs-tiled-active-border-color-before: var(--mo-brand-secondary);
  --mo-tabs-tiled-active-border-color-after: var(--mo-color-0);
  --mo-tile-color: var(--mo-color-100);
  --mo-tile-background-color: var(--mo-color-0);
  --mo-tile-border-color: var(--mo-color-20);
  --mo-tile-focus-background-color: var(--mo-color-0);
  --mo-tile-focus-border-color: var(--mo-brand-primary-active);
  --mo-tile-active-background-color: var(--mo-color-0);
  --mo-tile-active-border-color: var(--mo-brand-primary);
}

@media (prefers-reduced-motion: reduce) {
  .host {
    --mo-animation-duration-super-fast: 0;
    --mo-animation-duration-extra-fast: 0;
    --mo-animation-duration-fast: 0;
    --mo-animation-duration-normal: 0;
    --mo-animation-duration-slow: 0;
    --mo-animation-duration-super-slow: 0;
  }
}

@media (width >=48em) {
  .host {
    --mo-container-gutter: 48px;
    --mo-container-gap: 24px;
  }
}
