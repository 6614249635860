@use 'index.css';

@font-face {
  font-family: on-air-momentum;
  font-style: normal;
  font-weight: 300 900;
  font-stretch: 100% 100%;
  src: url('./onairvar.woff2') format('woff2-variations');
  font-display: swap;
}

.host {
  font-family: on-air-momentum, Arial, sans-serif;

  --color-footer: var(--color-primary-bg);
}
